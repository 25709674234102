// extracted by mini-css-extract-plugin
export var maineDigest = "maine_digest_2021_08-module--maineDigest--BwtCN";
export var heroImage = "maine_digest_2021_08-module--heroImage--y8hrr";
export var columnWrapper = "maine_digest_2021_08-module--columnWrapper--fqGqB";
export var heading = "maine_digest_2021_08-module--heading--kc7ca";
export var subheading = "maine_digest_2021_08-module--subheading---irEL";
export var latestnewsarticleheadline = "maine_digest_2021_08-module--latestnewsarticleheadline--jPvCg";
export var dateline = "maine_digest_2021_08-module--dateline--frjGC";
export var sectionHeader = "maine_digest_2021_08-module--sectionHeader--6-JdG";
export var photoCaption = "maine_digest_2021_08-module--photoCaption--N5kHG";
export var afterCaption = "maine_digest_2021_08-module--afterCaption--3iH6s";